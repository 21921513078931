import React, { useState } from "react";
import "./Post.css"
import { Shortlist } from "../ShortList/ShortList";
import { useNavigate } from "react-router-dom";

const ActionsDp = ({ item }) => {
    const frames_24 = localStorage.getItem('frames_24');
    let navigate = useNavigate()
    const SendMessage = (Data) => {
        console.log(Data)
        let id = Data?.user?.id
        navigate(`/messages/${id}`)
    }
    return (
        <>
            <dpostiv className="actionsdp-list">
                {/* <div className="message-btn" onClick={() => SendMessage(item)}>
                    <img src={require("../../Pages/images/messages.png")} width={20} alt="message-icon" />Message
                </div> */}
                <div className="message-btn">
                    <img src={require("../../Pages/images/messages.png")} width={20} alt="message-icon" />Message
                </div>
                {frames_24?.split(',')?.some(role =>
                    ["Executive Producer", "Casting Director", "Producer", "Director", "Associate Director", "Co-Director", "Assistant Director", "Production Manager"].includes(role.trim())
                ) &&
                    <Shortlist ShortlistId={item.id} />
                }
            </dpostiv>
        </>
    )
}
export { ActionsDp };