import React, { useState, useEffect, useRef } from "react";
import { Footer, Navbar } from "../Components";
import CustomTop from "./CustomTop";
import 'bootstrap/dist/css/bootstrap.min.css';
import ChatList from "../Components/Messages/ChatList";
import { ChatContent } from "../Components/Messages/ChatContent";
import { BiSearch } from 'react-icons/bi';
import axiosConfig from "../Service/axiosConfig"
import { MdOutlineInfo, MdOutlinePhone, MdOutlineVideocam } from "react-icons/md";
import { BsEmojiSmile } from "react-icons/bs";
import { RiImage2Fill } from "react-icons/ri";
import { TiMicrophoneOutline } from "react-icons/ti";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from '../Utils/AuthContext';
const MessagesPage = () => {
    let { id } = useParams();
    let navigate = useNavigate()
    const { isLoggedIn, is24Craft, socketUrl, messagelabel, setMessagelabel } = useAuth();
    let profile_data = JSON.parse(localStorage.getItem("profile_data"))
    const [selectedChat, setSelectedChat] = useState(id);
    const [chatlist, setChatlist] = useState([])
    const [filteredChatlist, setFilteredChatlist] = useState([]);
    const [message, setMessage] = useState('');
    const [chat, setChat] = useState([]);
    const [receiverId, setReceiverId] = useState("")
    const [search, SetSearch] = useState("")
    const chatRef = useRef(null);
    const formatTime = (timestamp) => {
        const date = new Date(timestamp);
        const options = {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };
        const formattedTime = date
            .toLocaleTimeString('en-US', options)
            .toLowerCase()
            .replace(' ', '');
        return formattedTime;
    };
    const handleChatSelection = (chatId) => {
        setSelectedChat(chatId);
        setReceiverId(chatId)
        navigate(`/messages/${chatId}`);
        fetchMessages(chatId);
    };
    const GetData = async () => {
        try {
            const responce = await axiosConfig.get(`/messagesapp/messages/chat_summary/`)
            setChatlist(responce.data)
            setFilteredChatlist(responce.data);
            setReceiverId(responce.data[0]?.user_id)
            if (chatRef.current) {
                chatRef.current.scrollTop = chatRef.current.scrollHeight;
            }
            if (!id && responce.data.length > 0) {
                fetchMessages(responce.data[0]?.user_id)
                setSelectedChat(responce.data[0]?.user_id);
                navigate(`/messages/${responce.data[0]?.user_id}`);
            }
        }
        catch (error) {
            console.log(error)
        }
    }
    const HandleSearch = (e) => {
        e.preventDefault()
        const value = e.target.value;
        SetSearch(value);
        const filtered = chatlist.filter(chat =>
            chat?.user_name?.toLowerCase().includes(value.toLowerCase())
        );
        console.log(filtered)
        setFilteredChatlist(filtered);
    };
    const fetchMessages = async (id) => {
        try {
            const response = await axiosConfig.get(`/messagesapp/messages/get_messages/?receiver=${id}`)
            setChat(response.data);
            if (chatRef.current) {
                chatRef.current.scrollTop = chatRef.current.scrollHeight;
            }
        } catch (error) {
            console.error("Error fetching messages:", error);
        }
    };
    const sendMessage = async () => {
        if (message) {
            socketUrl.emit('message', { receiver: selectedChat, content: message, sender: profile_data?.id });
            const formData = new FormData();
            formData.append('receiver', selectedChat);
            formData.append('content', message);
            axiosConfig.defaults.headers['Content-Type'] = 'multipart/form-data';
            try {
                const response = await axiosConfig.post('/messagesapp/messages/send_message/', formData);
                console.log("saved", response)
                GetData()
                fetchMessages(selectedChat)
            } catch (error) {
                console.log(error);
            }
            setMessage('');
        }
    };
    useEffect(() => {
        console.log(receiverId, id)
        socketUrl?.on('message', (data) => {
            console.log("New message received", data)
            if (JSON.stringify(data.sender) === id) {
                console.log("test")
                setChat((prevChat) => [...prevChat, data]);
            }
            GetData()
        });
        return () => socketUrl?.off('message');
    }, [receiverId]);
    useEffect(() => {
        GetData()
    }, [])
    useEffect(() => {
        if (id) {
            setSelectedChat(id);
            fetchMessages(id);
        }
    }, [id]);
    return (
        <>
            <CustomTop />
            <div className="search-screen-container search-page">
                <Navbar />
                <div className="content-area chat-container">
                    <div className="chat-list">
                        <h4>All Chats</h4>
                        <div className="searchbar">
                            <input type="search" placeholder="Search for people" name="search" value={search} onChange={HandleSearch} />
                            <BiSearch size={25} color='#9D9D9D' />
                        </div>
                        {/* <div className='chat-tabs'>
                            <div className='chat-type'>All</div>
                            <div>Unread</div>
                            <div>request</div>
                        </div> */}
                        <div className="chat-items">
                            {filteredChatlist.map((chat, index) => (
                                < div
                                    key={index}
                                    className={`chat-item ${parseInt(id) === chat?.user_id ? 'active' : ''}`}
                                    onClick={() => handleChatSelection(chat?.user_id)}
                                >
                                    <img src={require(`../Components/Post/images/male.jpg`)} alt="profile-pic" width="40" className="profile-pic" />
                                    <div className="chat-details">
                                        <h5>{chat?.user_name}</h5>
                                        <p>{chat?.last_message}</p>
                                    </div>
                                    <div className="chat-meta">
                                        <span className="time">{formatTime(chat?.timestamp)}</span>
                                        {chat?.unread_count > 0 && <span className="unread-count">{chat?.unread_count}</span>}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="chat-window">
                        <div className="chat-header">
                            <div className="user-info">
                                <div className='chat-image'>
                                    <div className='chat-dot'></div>
                                    <img src={require('../Components/Post/images/male.jpg')} alt="profile" className="profile-pic" />
                                </div>
                                <div>
                                    <div className='header-name'>{selectedChat}</div>
                                    <p className="status">Active Now</p>
                                </div>
                            </div>
                            <div className="chat-actions">
                                {/* <MdOutlinePhone size={30} />
                                <MdOutlineVideocam size={32} />
                                <MdOutlineInfo size={32} /> */}
                            </div>
                        </div>
                        <div className="chat-body">
                            <div className='chat_messages_scroll' ref={chatRef}>
                                {chat.map((msg, index) => {
                                    return (
                                        <div key={index} className={`chat-message ${msg.sender === profile_data?.id ? 'sent' : 'received'}`} >
                                            < div className="message-content" >
                                                {msg.sender !== profile_data?.id && <img src={require('../Components/Post/images/male.jpg')} alt="profile" className="profile-pic-small" />}
                                                < div className="message-text" >
                                                    <p>{msg.content}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                        < div className="message-input" >
                            {/* <BsEmojiSmile size={27} /> */}
                            <input type="text" placeholder="Type here..." value={message} onChange={(e) => setMessage(e.target.value)} onKeyPress={(e) => e.key === "Enter" && sendMessage()} />
                            {/* <RiImage2Fill size={30} />
                            <TiMicrophoneOutline size={30} /> */}
                            {message !== "" && <div className="send-button" onClick={sendMessage} >Send</div>}
                        </div>
                    </div >
                </div >
            </div >
            <Footer />
        </>
    );
}

export { MessagesPage };