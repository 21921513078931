import React, { useState, useEffect } from "react";
import axiosConfig from "../Service/axiosConfig"
import { Footer, Navbar, DeletePopupModal, FormParser, Follow } from "../Components";
import { useNavigate, useSearchParams, NavLink, useParams } from "react-router-dom";
import CustomTop from "./CustomTop";
import { AlertDeletePopupModal } from "../Components/PopupModal/AlertDeletePopupModal"
import { MdMoreVert, MdOutlineArrowBackIos, MdOutlineLocalPhone, MdOutlineMoreVert, MdOutlineRemoveRedEye, MdOutlineTouchApp, MdOutlineWatchLater } from "react-icons/md";
import { Card, Row, Col, Container } from 'react-bootstrap';
import { AuditionApplications } from "./AuditionApplications";
import { Modal, Button } from 'react-bootstrap';
import Masters from "../Components/masters.json"
import { UpgradePage } from "./UpgradePage";
import { GrAlarm } from "react-icons/gr";
import checkProRealtime from '../Utils/checkProRealtime';

const AuditionDetailPage = ({ }) => {
    let { id } = useParams()
    const profile_data = JSON.parse(localStorage.getItem('profile_data'));
    const [loading, setLoading] = useState(false)
    const [Data, setData] = useState({})
    const [requirementData, setRequirementData] = useState([])
    const [AudiData, setAudiData] = useState([])
    const [show, setShow] = useState(false);
    const [AlertDeletePopupType, SetAlertDeletePopupType] = useState("")
    const [deletePopupType, setDeletepopupType] = useState("")
    const [RowId, SetRowId] = useState()
    const [AlertDeletePopupType1, SetAlertDeletePopupType1] = useState("")
    const [deletePopupType1, setDeletepopupType1] = useState("")
    const [RowId1, SetRowId1] = useState()
    const [AlertDeletePopupType2, SetAlertDeletePopupType2] = useState("")
    const [deletePopupType2, setDeletepopupType2] = useState("")
    const [requirementShow, setRequirementShow] = useState(false)
    const [auditionRequirements, setAuditionRequirements] = useState([]);
    const [UpgradeShow, setUpgradeShow] = useState(false)
    const [ScreenVisbile, setScreenVisbile] = useState("plans")
    const handleUpgradeClose = () => {
        setUpgradeShow(false)
        setScreenVisbile("plans")
    }
    const HandleUpgrade = () => {
        setUpgradeShow(true)
    }
    const fetchAuditionList = async (profile_data, id) => {
        setLoading(true);
        try {
            const response = await axiosConfig.get(`accounts/audition-applications/?profile=${profile_data?.profile_id}&audition_requirement=&audition_requirement__audition=${id}&application_status=`);
            const auditionRequirements = response.data.results.map(item => item.audition_requirement);
            setAuditionRequirements(auditionRequirements);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    const handleSmallClose = () => setShow(false);
    const handleShow = () => {
        if (localStorage.getItem('token')) {
            console.log(localStorage.getItem("is_pro"))
            if (localStorage.getItem('is_pro') === "true") {
                setShow(true);
            }
            else {
                HandleUpgrade()
            }
        } else {
            handleLoginShow();
        }
    }
    const GetData = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/auditions/${id}/`)
            if (responce.data?.profile?.id !== profile_data?.profile_id) {
                AuditionView()
            }
            setAudiData(responce.data)
            setLoading(false)
        }
        catch (error) {
            console.log(error)
            setLoading(false)
        }
    }
    const handlShow = (id) => {
        Navigate(`/audition-applications/${id}`)
    }
    const handleLoginShow = () => {
        document.querySelector('.login-text').click();
    }
    const [showApply, setshowApply] = useState(false)
    const [profilePic, setProfilepic] = useState(false)
    const HandleProfilepic = () => {
        if (profile_data?.profile_pic === null) {
            setProfilepic(true)
        } else if (localStorage.getItem('is_24_craft') === null) {
            setshowApply(true)
        }
        else {
            HandleUpgrade();
        }
    }
    const DeleteAlert = (id) => {
        if (localStorage.getItem('token')) {
            SetRowId(id)
            if (localStorage.getItem('is_pro') === "true") {
                const checkCondition = async () => {
                    await checkProRealtime.checkCondition(); // Alerts and reloads will be handled inside the service
                };
                checkCondition();
                SetAlertDeletePopupType("success")
            } else {
                HandleProfilepic()
            }
        } else {
            handleLoginShow();
        }
        // if (localStorage.getItem('token')) {
        //     console.log(localStorage.getItem("is_pro"))
        //     if (localStorage.getItem('is_pro') === "true") {
        //         SetRowId(id)
        //         SetAlertDeletePopupType("success")
        //     }
        //     else {
        //         HandleUpgrade()
        //     }
        // }
    }
    const DeleteAlert1 = (id) => {
        SetRowId1(id)
        SetAlertDeletePopupType1("success")
    }
    const CancelAlerrt1 = () => {
        SetAlertDeletePopupType1("error")
    }
    const CancelAlerrt = () => {
        SetAlertDeletePopupType("error")
    }
    const HandleDelete = (id) => {
        console.log(id)
        axiosConfig.delete(`/accounts/auditions/${id}/`).then(res => {
            console.log(res)
            Navigate(`/auditions`)
            setDeletepopupType("success")
            SetAlertDeletePopupType("error")
        }).catch(error => {
            console.log(error)
        })
    }
    const handleApply = (id) => {
        console.log(id)
        axiosConfig.post("/accounts/audition-applications/", { audition_requirement: id }).then(res => {
            console.log(res)
            setDeletepopupType("success")
            SetAlertDeletePopupType("error")
        }).catch(error => {
            console.log(error)
        })
    }
    function getProjectTypeColor(status) {
        switch (status) {
            case 'Movies':
                return '#8b008b';
            case 'OTT':
                return '#696969';
            case 'TV_Serials':
                return '#8b4513';
            case 'Web_Series':
                return '#4682b4';
            case 'Music_Albums':
                return '#bdb76b';
            case 'Cover_Songs':
                return '#a52a2a';
            case 'Item_Song':
                return '#c71585';
            case 'Short_Films':
                return '#228b22';
            case 'Ads':
                return '#d2691e';
            case 'Modelling':
                return '#20b2aa';
            case 'Audition':
                return '#db7093';
            default:
                return '#ffffff';
        }
    }
    function getProjectType(status) {
        switch (status) {
            case 'Movies':
                return '#f5d8ff';
            case 'OTT':
                return '#c1c1cc';
            case 'TV_Serials':
                return '#ffd8b1';
            case 'Web_Series':
                return '#b3ecff';
            case 'Music_Albums':
                return '#f0e68c';
            case 'Cover_Songs':
                return '#ffcccb';
            case 'Item_Song':
                return '#ff69b4';
            case 'Short_Films':
                return '#b2fab4';
            case 'Ads':
                return '#ffdab9';
            case 'Modelling':
                return '#e0ffff';
            case 'Audition':
                return '#ffb6c1';
            default:
                return '#ffffff';
        }
    }
    const AuditionView = async () => {
        try {
            const responce = await axiosConfig.get(`/accounts/auditions/${id}/viewed/`)
        }
        catch (error) {
            console.log(error);
        }
    }
    const Navigate = useNavigate();
    const handleNavigate = (link, type) => {
        console.log(link, type);
        if (type === "profile") {
            Navigate(`/profile/${link}`)
        }
        if (type === "back") {
            Navigate(-1)
        }
        if (type === "edit") {
            Navigate(`/auditions/${id}/edit`)
        }
    }
    const GetRequirementdata = async () => {
        setLoading(true)
        try {
            const responce = await axiosConfig.get(`/accounts/audition-requirements/?audition=${id}`)
            setRequirementData(responce.data.results);
            setLoading(false);
        }
        catch (error) {
            console.log(error)
            setLoading(false);
        }
    }
    const DeleteAlerttt = (id) => {
        SetRowId(id)
        SetAlertDeletePopupType2("success")
    }
    const CancelAlerrttt = () => {
        SetAlertDeletePopupType2("error")
    }
    const HandleDeleted = async (id) => {
        await axiosConfig.delete(`/accounts/audition-requirements/${id}/`).then(() => {
            SetAlertDeletePopupType2("error")
            setDeletepopupType2("success")
            GetRequirementdata();
        }).catch((error) => {
            setDeletepopupType2("error")
            SetAlertDeletePopupType2("error")
            console.log(error)
        })
    }
    const AudiRequirements = (data) => {
        console.log(Data)
        const orderTopost = {
            ...data,
            audition: id
        }
        const orderToPatch = {
            ...data,
            audition: id,
            approval_status: "requested"
        }
        console.log(orderTopost, orderToPatch)
        if (Data.id) {
            axiosConfig.patch(`/accounts/audition-requirements/${Data.id}/`, orderToPatch).then(res => {
                console.log(res)
                GetRequirementdata()
                setRequirementShow(false)
            }).catch(error => {
                console.log(error.response.data)
                // setpopupType("error"); 
            })
        } else {
            axiosConfig.post("/accounts/audition-requirements/", orderTopost).then(res => {
                console.log(res)
                GetRequirementdata()
                setRequirementShow(false)
            }).catch(error => {
                console.log(error.response.data)
            })
        }
    }
    const shareContent = async (share_to, data) => {
        try {
            let baseUrl = `${window.location.protocol}//${window.location.host}`;
            let URL = `${baseUrl}/auditions/${data.id}/`;
            let text = `${data?.banner_name ? `${data.banner_name}` : ""} ${data?.project_type ? `- ${data.project_type}` : ""} ${data?.project_title ? `- (Title : ${data.project_title})` : ""}  ${data?.zoner ? `- (Zoner : ${data.zoner})` : ""} ${data?.loc_lang?.city ? `- ${data.loc_lang?.city} (${data.loc_lang?.state})` : ""} ${data?.director_name ? `- ${data?.director_name}(Director)` : ""} ${data?.hero ? `- ${data?.hero}(Hero)` : ""} ${data?.producer_name ? `- ${data?.producer_name}(Producer)` : ""} ${URL}`
            const shareUrl = encodeURIComponent(text);
            if (share_to === "whatsapp") {
                window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
            } else if (share_to === "fb") {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
            } else {
                if (navigator.share) {
                    await navigator.share({
                        title: 'Share Post',
                        text: `${data?.banner_name ? `${data.banner_name}` : ""} ${data?.project_type ? `- ${data.project_type}` : ""} ${data?.project_title ? `- (Title : ${data.project_title})` : ""}  ${data?.zoner ? `- (Zoner : ${data.zoner})` : ""} ${data?.loc_lang?.city ? `- ${data.loc_lang?.city} (${data.loc_lang?.state})` : ""} ${data?.director_name ? `- ${data?.director_name}(Director)` : ""} ${data?.hero ? `- ${data?.hero}(Hero)` : ""} ${data?.producer_name ? `- ${data?.producer_name}(Producer)` : ""} ${URL}`
                        // text: `${shareUrl}`
                    });
                }
            }
        } catch (err) {
            console.error('Failed to share content:', err);
        }
    };
    const HandleEdit = (id) => {
        setData({})
        axiosConfig.get(`/accounts/audition-requirements/${id}/`).then(res => {
            console.log(res.data)
            setData({
                ...res.data,
            })
            setRequirementShow(true)
        }).catch(error => {
            console.log(error)
        })
    }
    const copyToClipboard = async (data) => {
        let baseUrl = `${window.location.protocol}//${window.location.host}`;
        let shareUrl = `${baseUrl}/auditions/${id}/`;
        try {
            await navigator.clipboard.writeText(
                `${data?.banner_name ? `${data.banner_name}` : ""} ${data?.project_type ? `- ${data.project_type}` : ""} ${data?.project_title ? `- (Title : ${data.project_title})` : ""} ${data?.zoner ? `- (Zoner : ${data.zoner})` : ""} ${data?.loc_lang?.city ? `- ${data.loc_lang?.city} (${data.loc_lang?.state})` : ""} ${data?.director_name ? `- ${data?.director_name}(Director)` : ""} ${data?.hero ? `- ${data?.hero}(Hero)` : ""} ${data?.producer_name ? `- ${data?.producer_name}(Producer)` : ""} ${shareUrl} `
            );
            console.log('Text copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy text to clipboard:', err);
        }
    };
    const ShareRequirement = async (share_to, data) => {
        console.log(data)
        try {
            let baseUrl = `${window.location.protocol}//${window.location.host}`;
            let URL = `${baseUrl}/audi-requirements/${data.id}/`;
            let text = `${data?.character ? `Required ${data.character}` : ""} ${AudiData?.project_type ? `for ${AudiData.project_type}` : ""} ${data?.gender ? `- (Gender : ${data.gender})` : ""} ${data?.from_age ? `- Age(${data.from_age}-${data.to_age})yrs` : ""} ${data?.loc_lang?.languages ? `- (Languages : ${data.loc_lang.languages})` : "- Languages : Any Language"} ${data?.loc_lang?.state ? `- (Location : ${data?.loc_lang?.state})` : " - Location : Any Location"} ${AudiData?.banner_name ? `- (Banner : ${AudiData.banner_name})` : ""} ${URL} `
            // let text = `${data?.character ? `Required ${data.character}` : ""} ${data?.gender ? `- (Gender : ${data.gender})` : ""} ${data?.from_age ? `- Age(${data.from_age}-${data.to_age})yrs` : ""} ${data?.loc_lang?.languages ? `- (Languages : ${data.loc_lang.languages})` : "- Languages : Any Language"} ${data?.loc_lang?.state ? `- (Location : ${data?.loc_lang?.state})` : " - Location : Any Location"} ${URL} `
            const shareUrl = encodeURIComponent(text);
            if (share_to === "whatsapp") {
                window.open(`https://api.whatsapp.com/send?text=${shareUrl}`, '_blank');
            } else if (share_to === "fb") {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`, '_blank');
            } else {
                if (navigator.share) {
                    await navigator.share({
                        title: 'Share Post',
                        text: `${data?.character ? `Required ${data.character}` : ""} ${AudiData?.project_type ? `for ${AudiData.project_type}` : ""} ${data?.gender ? `- (Gender : ${data.gender})` : ""} ${data?.from_age ? `- Age(${data.from_age}-${data.to_age})yrs` : ""} ${data?.loc_lang?.languages ? `- (Languages : ${data.loc_lang.languages})` : "- Languages : Any Language"} ${data?.loc_lang?.state ? `- (Location : ${data?.loc_lang?.state})` : " - Location : Any Location"} ${AudiData?.banner_name ? `- (Banner : ${AudiData.banner_name})` : ""} ${URL} `
                        // text: `${data?.character ? `Required ${data.character}` : ""} ${data?.gender ? `- (Gender : ${data.gender})` : ""} ${data?.from_age ? `- Age(${data.from_age}-${data.to_age})yrs` : ""} ${data?.loc_lang?.languages ? `- (Languages : ${data.loc_lang.languages})` : "- Languages : Any Language"} ${data?.loc_lang?.state ? `- (Location : ${data?.loc_lang?.state})` : " - Location : Any Location"} ${URL} `
                    });
                }
            }
        } catch (err) {
            console.error('Failed to share content:', err);
        }
    };
    const CopyRequirement = async (data) => {
        console.log(data, AudiData)
        let baseUrl = `${window.location.protocol}//${window.location.host}`;
        let shareUrl = `${baseUrl}/audi-requirements/${data.id}/`;
        try {
            await navigator.clipboard.writeText(
                `${data?.character ? `Required ${data.character}` : ""} ${AudiData?.project_type ? `for ${AudiData.project_type}` : ""} ${data?.gender ? `- (Gender : ${data.gender})` : ""} ${data?.from_age ? `- Age(${data.from_age}-${data.to_age})yrs` : ""} ${data?.loc_lang?.languages ? `- (Languages : ${data.loc_lang.languages})` : "- Languages : Any Language"} ${data?.loc_lang?.state ? `- (Location : ${data?.loc_lang?.state})` : " - Location : Any Location"} ${AudiData?.banner_name ? `- (Banner : ${AudiData.banner_name})` : ""} ${shareUrl} `
            );
            console.log('Text copied to clipboard!');
        } catch (err) {
            console.error('Failed to copy text to clipboard:', err);
        }
    };
    function timeAgo(dateString) {
        const now = new Date();
        const pastDate = new Date(dateString);
        const secondsAgo = Math.floor((now - pastDate) / 1000);
        const minutesAgo = Math.floor(secondsAgo / 60);
        const hoursAgo = Math.floor(minutesAgo / 60);
        const daysAgo = Math.floor(hoursAgo / 24);
        if (daysAgo > 0) return `${daysAgo}d${daysAgo > 1 ? '' : ''} ago`;
        if (hoursAgo > 0) return `${hoursAgo}hr${hoursAgo > 1 ? 's' : ''} ago`;
        if (minutesAgo > 0) return `${minutesAgo}min${minutesAgo > 1 ? 's' : ''} ago`;
        return `${secondsAgo}s`;
    }
    const AuditionClose = (Audi_id) => {
        axiosConfig.patch(`/accounts/auditions/${Audi_id}/`, { status: "completed" }).then(res => {
            console.log(res);
            GetData()
        }).catch(error =>
            console.log(error)
        );
    }
    const RequirementClose = (Audi_id, statustype) => {
        const Data = {
            requirement_status: statustype
        }
        console.log(Data)
        axiosConfig.patch(`/accounts/audition-requirements/${Audi_id}/`, Data).then(res => {
            console.log(res);
            GetRequirementdata()
        }).catch(error =>
            console.log(error)
        );
    }
    useEffect(() => {
        GetData()
        GetRequirementdata()
    }, [])
    useEffect(() => {
        if (profile_data) {
            fetchAuditionList(profile_data, id)
        }
    }, [id])
    return (
        <>
            <CustomTop />
            <div className="search-screen-container">
                <Navbar />
                <div className="content-area auditions_details_container" style={{ background: "#F0F5F8" }}>
                    <div className="Grid-page auditionsdetails_conatiner">
                        <div className="investment-screen">
                            <div className="contents">
                                <div className="card bax-shadow" style={{ backgroundColor: getProjectType(AudiData.project_type) }}>
                                    <div className="d-flex align-items-center justify-content-center">
                                        <div className="card-type-grid" style={{ backgroundColor: getProjectTypeColor(AudiData.project_type) }}>{AudiData.project_type ? AudiData.project_type : "---"}</div>
                                    </div>
                                    <div className='card-content'>
                                        <div className="flex-between-align">
                                            <div onClick={() => handleNavigate("", "back")} className="back-button"><MdOutlineArrowBackIos size={20} /> Back</div>
                                            {AudiData.banner_name &&
                                                <h4 className="banner-title">{AudiData.banner_name}</h4>
                                            }
                                            <div className="dropdown back-button">
                                                <MdOutlineMoreVert data-bs-toggle="dropdown" size={25} />
                                                <ul className="dropdown-menu">
                                                    {AudiData?.profile?.id === profile_data?.profile_id &&
                                                        <>
                                                            <li><a className="dropdown-item" onClick={() => handleNavigate(AudiData.id, "edit")}>edit</a></li>
                                                            <li><a className="dropdown-item" onClick={() => DeleteAlert1(AudiData.id)}>Delete</a></li>
                                                            {/* <li><a className="dropdown-item" onClick={() => AuditionClose(AudiData.id)}>Close</a></li>
                                                            <li><a className="dropdown-item" onClick={() => AuditionClose(AudiData.id)}>Open</a></li> */}
                                                        </>
                                                    }
                                                    <li><a className="dropdown-item" href="#!" onClick={() => copyToClipboard(AudiData)}>Copy URL</a></li>
                                                    <li><a className="dropdown-item" href="#!" onClick={() => shareContent("share", AudiData)}>Share</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="card-descrt card-descrts">
                                            <div className="card-descrt-inners">
                                                <div className="project_title">
                                                    <div className="audi-id">AD{AudiData.id && AudiData.id}</div>
                                                    <div>
                                                        <div className='Audi-project-title'>{AudiData.project_title && AudiData.project_title}</div>
                                                        <div className='Audi-description'>{AudiData.generated_requirement && AudiData.generated_requirement}</div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-between">
                                                    {AudiData.director_name && <div className="card-botton-centent">
                                                        <div className="card-cont-title" style={{ fontSize: "14px" }}>DIRECTOR</div>
                                                        <div className='card-cont-detail about-card' style={{ fontSize: '16px' }}>{AudiData.director_name}</div>
                                                    </div>}
                                                    {AudiData.producer_name && <div className="card-botton-centent">
                                                        <div className="card-cont-title" style={{ fontSize: "14px" }}>PRODUCER</div>
                                                        <div className='card-cont-detail' style={{ fontSize: '16px' }}>{AudiData.producer_name}</div>
                                                    </div>}
                                                    {AudiData.hero_name && <div className="card-botton-centent">
                                                        <div className="card-cont-title" style={{ fontSize: "14px" }}>HERO</div>
                                                        <div className='card-cont-detail' style={{ fontSize: '16px' }}>{AudiData.hero_name}</div>
                                                    </div>}
                                                </div>
                                                <div className="card-detail">
                                                    <div className="card-botton-centent" style={{ justifyContent: 'flex-start' }}>
                                                        <div className="card-cont-title">Zoner</div>
                                                        <div className='card-cont-detail'>{AudiData.zoner ? AudiData.zoner : "-"}</div>
                                                    </div>
                                                    {AudiData.platform && <div className="card-botton-centent" style={{ justifyContent: 'flex-start' }}>
                                                        <div className="card-cont-title">Platform</div>
                                                        <div className='card-cont-detail'>{AudiData.platform}</div>
                                                    </div>}
                                                    {AudiData.loc_lang?.state && AudiData.loc_lang?.city && (
                                                        <div className="card-botton-centent" style={{ justifyContent: 'flex-start' }}>
                                                            <div className="card-cont-title">Location</div>
                                                            <div className='card-cont-detail'>
                                                                {AudiData.loc_lang.city}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="auditionLists">
                                                    <div style={{ display: "flex", flexDirection: 'column' }}>
                                                        <div className="card-cont-title">Description</div>
                                                        <div className='card-cont-detail'>
                                                            {AudiData.description && AudiData.description.trim() !== "" ? AudiData.description : "No requirements available"}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="right-panel">
                                                <div className="investment-box bax-shadow">
                                                    <div>
                                                        <label>{AudiData.project_type} Budget</label>
                                                        <input type="text" value={AudiData.budget ? AudiData.budget : 0}
                                                            disabled />
                                                    </div>
                                                    <div className="hide-mobile">
                                                        <div className="card-actions">
                                                            <div className="card-botton-centent">
                                                                <div className="card-cont-title">Applications</div>
                                                                <div className='card-cont-detail'>{AudiData.applications ? AudiData.applications : "-"}</div>
                                                            </div>
                                                            <div className="card-botton-centent">
                                                                <div className="card-cont-title">Views</div>
                                                                <div className='card-cont-detail'>{AudiData.views}</div>
                                                            </div>
                                                        </div>
                                                        <div className="card-botton-centent">
                                                            <div className="card-cont-title">Posted On</div>
                                                            <div className='card-cont-detail'>
                                                                {AudiData.start_date
                                                                    ? new Date(AudiData.start_date).toLocaleDateString('en-GB').split('/').join("-")
                                                                    : "-"}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="d-flex justify-content-between">
                                                            <button className="btn btn-primary w-100" onClick={handleShow}>Contact Details</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="investment-screen d-flex justify-content-between align-items-center">
                            <h5>Requirements</h5>
                            {AudiData?.profile?.id === profile_data?.profile_id &&
                                <button className="btn btn-primary" onClick={() => { setRequirementShow(true); setData({}) }}>Add New Requirement</button >
                            }
                        </div>
                        <div className="investment-screen">
                            <Row>
                                {requirementData.map((requirement, index) => (
                                    <Col xl={4} lg={4} md={6} sm={12} xs={12} key={index}>
                                        <Card className="bax-shadow auditions_box_minheight" style={{ position: "relative" }}>
                                            <Card.Body>
                                                <div className="Require-drop">
                                                    <div className="dropdown">
                                                        <MdOutlineMoreVert data-bs-toggle="dropdown" size={25} />
                                                        <ul className="dropdown-menu">
                                                            {AudiData?.profile?.id === profile_data?.profile_id &&
                                                                <>
                                                                    {requirement?.requirement_status === "live" &&
                                                                        <>
                                                                            <li><a className="dropdown-item" onClick={() => { HandleEdit(requirement.id) }}>edit</a></li>
                                                                            <li><a className="dropdown-item" onClick={() => DeleteAlerttt(requirement.id)}>Delete</a></li>
                                                                            <li><a className="dropdown-item" onClick={() => RequirementClose(requirement?.id, "closed")}>Close</a></li>
                                                                        </>
                                                                    }
                                                                    {requirement?.requirement_status === "closed" &&
                                                                        <li><a className="dropdown-item" onClick={() => RequirementClose(requirement?.id, "live")}>Open</a></li>
                                                                    }
                                                                </>
                                                            }
                                                            <li><a className="dropdown-item" href="#!" onClick={() => ShareRequirement("share", requirement)}>Share</a></li>
                                                            <li><a className="dropdown-item" href="#!" onClick={() => CopyRequirement(requirement)}>Copy</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <Card.Title className="card-title-custom">Role - {requirement.character}</Card.Title>
                                                <Card.Text>
                                                    <div className="applictn-dtls">
                                                        {requirement.gender && (
                                                            <div className="row">
                                                                <div className="apply-content col-6"><span>Gender</span><span>:</span></div>
                                                                <div className="col-6 apply-data">{requirement.gender}</div>
                                                            </div>)}
                                                        {requirement.from_age &&
                                                            <div className="row">
                                                                <div className="apply-content col-6"><span>Age</span><span>:</span></div>
                                                                <div className="col-6 apply-data">{requirement.from_age} - {requirement.to_age} years</div>
                                                            </div>}
                                                        {requirement.budget &&
                                                            <div className="row">
                                                                <div className="apply-content col-6"><span>Budget</span><span>:</span></div>
                                                                <div className="col-6 apply-data">{requirement.budget ? requirement.budget : 0}</div>
                                                            </div>}
                                                        {requirement.no_of_requirements &&
                                                            <div className="row">
                                                                <div className="apply-content col-6"><span>No. of Positions</span><span>:</span></div>
                                                                <div className="col-6 apply-data">{requirement.no_of_requirements}</div>
                                                            </div>}
                                                        <div className="row">
                                                            <div className="apply-content col-6"><span>Preffered Languages</span><span>:</span></div>
                                                            <div className="col-6 apply-data">{requirement?.loc_lang?.languages ? requirement?.loc_lang?.languages : "Any Language"}</div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="apply-content col-6"><span>Preffered Location</span><span>:</span></div>
                                                            <div className="col-6 apply-data">{requirement?.loc_lang?.city
                                                                ? requirement.loc_lang.city : requirement?.loc_lang?.state ? requirement.loc_lang.state : "Any Location"
                                                            }</div>
                                                        </div>
                                                        {requirement?.specification &&
                                                            <div className="row auditionList">
                                                                <div className="apply-data">{requirement.specification}</div>
                                                            </div>}
                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Footer className="text-center" style={{ background: "none", border: "none" }}>
                                                <div className="card-bottom">
                                                    <div className="card-bottom-lefts">
                                                        <div className="card-bottom-detail"><GrAlarm size={14} /><span style={{ marginLeft: "5px" }}>{timeAgo(requirement?.created_at)}</span></div>
                                                        <div className="card-bottom-detail"><MdOutlineRemoveRedEye size={14} /><span style={{ marginLeft: "5px" }}>{requirement?.audition?.views}</span></div>
                                                        <div className="card-bottom-detail" style={{ color: "#212529" }}>Applications <span style={{ marginLeft: "5px" }}> ({requirement?.applications})</span></div>
                                                    </div>
                                                    <div className="view-button-profile">
                                                        {AudiData?.profile?.id === profile_data?.profile_id ? (
                                                            <button type="button" className="btn btn-primary btn-block" onClick={() => handlShow(requirement.id)}>View Applications({requirement.applications})</button>
                                                        ) : (
                                                            auditionRequirements?.includes(requirement.id) ? (
                                                                <>
                                                                    <button type="button" className="btn btn-primary btn-block" disabled>Applied</button>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <button type="button" className="btn btn-primary btn-block" onClick={() => DeleteAlert(requirement.id)} disabled={requirement.requirement_status === "closed"}>
                                                                        Apply
                                                                    </button>
                                                                </>
                                                            )
                                                        )}
                                                    </div>
                                                </div>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </div>
                    </div>
                </div>
            </div >
            <Footer />
            <Modal show={show} onHide={handleSmallClose}
                size="md"
                centered className="contactdetails_model_modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title>Contact Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Container>
                            <Row>
                                <Col>
                                    <div className='contact_profileheader'>
                                        <div className="contact_propfileinfor">
                                            <div className="contact_profile_right">
                                                <img src={AudiData.profile?.profile_pic ? AudiData.profile?.profile_pic : require('../Components/Post/images/male.jpg')} className="dp" alt={AudiData.profile?.first_name} />
                                            </div>
                                            <div className="contact_profile_title">
                                                {AudiData.profile?.first_name && <h5>{AudiData.profile?.first_name}</h5>}
                                                {AudiData.profile?.designation && <h6>{AudiData.profile?.designation}</h6>}
                                            </div>
                                        </div>
                                        <Follow FollowId={AudiData?.profile?.id} />
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8}>
                                    <Row>
                                        <Col xs={5}>
                                            <div className="contact_informationuser">
                                                <span>Name</span><span>:</span>
                                            </div>
                                        </Col>
                                        <Col xs={7}>
                                            <div className="contact_informationuser">
                                                {AudiData.contact_name && <span>{AudiData.contact_name}</span>}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={5}>
                                            <div className="contact_informationuser">
                                                <span>Designation</span><span>:</span>
                                            </div>
                                        </Col>
                                        <Col xs={7}>
                                            <div className="contact_informationuser">
                                                {AudiData.contact_name && <span> {AudiData.contact_designation}</span>}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={5}>
                                            <div className="contact_informationuser">
                                                <span>Mobile No</span><span>:</span>
                                            </div>
                                        </Col>
                                        <Col xs={7}>
                                            <div className="contact_informationuser">
                                                {AudiData.contact_name && <span>{AudiData?.contact_mobile_no}</span>}
                                            </div>
                                        </Col>
                                    </Row>
                                    {/* <Row>
                                        <Col xs={5}>
                                            <div className="contact_informationuser">
                                                <span>Email</span><span>:</span>
                                            </div>
                                        </Col>
                                        <Col xs={7}>
                                            <div className="contact_informationuser">
                                                <span>jhondoe@123gmail.com</span>
                                            </div>
                                        </Col>
                                    </Row> */}
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row>
                            <Col div className="contact_shareheader">
                                <Col div className="message_button">
                                    <img src={require("./images/messages.png")} width={20} alt="profile" />  Message
                                </Col>
                                <Col div className="contact_button" onClick={handleSmallClose}>
                                    <a href={`tel:${AudiData?.contact_mobile_no}`}><MdOutlineLocalPhone size={20} /> Call Now</a>
                                </Col>

                            </Col>
                        </Row>
                    </Container>
                    {/* <Button variant="secondary w-100" onClick={handleSmallClose}>
                        <a href={`tel:${AudiData?.contact_mobile_no}`}><MdOutlineLocalPhone size={20} /> Call Now</a>
                    </Button> */}
                </Modal.Footer>
            </Modal>
            <Modal
                size="lg"
                show={requirementShow}
                onHide={() => setRequirementShow(false)}
                aria-labelledby="example-modal-sizes-title-lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                        Add Audition Requirements
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormParser modelObject={Masters.AditionRequirements} formData={Data} formSubmit={AudiRequirements} error={Error} />
                    {Data.id && <div class="alert alert-danger">If you change requirement it will be temporarily removed from live until admin approves the it</div>}
                </Modal.Body>
            </Modal>
            {UpgradeShow &&
                <UpgradePage UpgradeShow={UpgradeShow} setUpgradeShow={setUpgradeShow} ScreenVisbile={ScreenVisbile} setScreenVisbile={setScreenVisbile} handleUpgradeClose={handleUpgradeClose} />
            }
            {
                deletePopupType !== "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType} deletePopupType={deletePopupType} message={"Applied for Audition SuccessFully!"} />
            }
            {
                AlertDeletePopupType === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType} SetAlertDeletePopupType={SetAlertDeletePopupType} HandleDelete={handleApply} CancelAlerrt={CancelAlerrt} row={RowId} message={"Are You Sure You Want Apply for this Audition ?"} />
            }
            {
                deletePopupType2 !== "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType2} deletePopupType={deletePopupType2} message={"Audition Requirement deleted SuccessFully!"} />
            }
            {
                AlertDeletePopupType2 === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType2} SetAlertDeletePopupType={SetAlertDeletePopupType2} HandleDelete={HandleDeleted} CancelAlerrt={CancelAlerrttt} row={RowId} message={"Are You Sure You Want Apply for this Audition Requirement ?"} />
            }
            {
                deletePopupType1 !== "" &&
                <DeletePopupModal setDeletepopupType={setDeletepopupType1} deletePopupType={deletePopupType1} message={"Applied for Audition SuccessFully!"} />
            }
            {
                AlertDeletePopupType1 === "success" &&
                <AlertDeletePopupModal AlertDeletePopupType={AlertDeletePopupType1} SetAlertDeletePopupType={SetAlertDeletePopupType1} HandleDelete={HandleDelete} CancelAlerrt={CancelAlerrt1} row={RowId1} message={"Are You Sure You Want Delete this Audition ?"} />
            }
        </>
    )
}
export { AuditionDetailPage };